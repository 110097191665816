<template>
  <div class="home">
    <h3 style="text-align: left;">Setup <span style="font-size:10px;">mongo</span>db and auth</h3>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  
    <!-- <HelloWorld msg="Welcome to Your Vue.js App Biotch"/> -->
    <div class="row">
      <div class="col form-inline">
        <b-form-input v-model="newTask" placeholder="Enter Task" @keyup.enter="add"></b-form-input> 
        <b-button class="ml-2" variant="primary" @click="add">Add</b-button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-3">
        <div class="p-2 alert alert-secondary">
          <h3>Backlog Bro</h3>
          <draggable class="list-group" :list="arrBacklog" group="tasks">
            <div class="list-group-item" v-for="element in arrBacklog" :key="element.name">
              {{element.name}}
            </div>
          </draggable>
        </div>
      </div>
      <div class="col-md-3">
        <div class="p-2 alert alert-warning">
          <h3>In Progress</h3>
          <draggable class="list-group" :list="arrInProgress" group="tasks">
            <div class="list-group-item" v-for="element in arrInProgress" :key="element.name">
              {{element.name}}
            </div>
          </draggable>
        </div>
      </div>
      <div class="col-md-3">
        <div class="p-2 alert alert-success">
          <h3>Tested</h3>
          <draggable class="list-group" :list="arrTested" group="tasks">
            <div class="list-group-item" v-for="element in arrTested" :key="element.name">
              {{element.name}}
            </div>
          </draggable>
        </div>
      </div>
      <div class="col-md-3">
        <div class="p-2 alert alert-primary">
          <h3>Completed</h3>
          <draggable class="list-group" :list="arrDone" group="tasks">
            <div class="list-group-item" v-for="element in arrDone" :key="element.name">
              {{element.name}}
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col form-inline">
        <b-form-input v-model="newShortcut1" placeholder="Enter Shortcut" @keyup.enter="add"></b-form-input> 
        <b-button class="ml-2" variant="primary" @click="add">Add</b-button>
      </div>
    </div>
    <div class="row shortcuts-container">
      <draggable class="list-group-s shortcut" :list="shortcuts1" group="shortcuts1">
        <div class="list-group-item-s" v-for="shortcut in shortcuts1" :key="shortcut.id">
          <div class="p-2 alert alert-success">
            <h3>{{shortcut.title}}</h3>
            
                <div style="color: hotpink;">
                  {{shortcut.description}}
                  <draggable class="list-group" :list="arrBacklog" group="tasks">
                    <div style="color: cornflowerblue;" class="list-group-item" v-for="element in arrBacklog" :key="element.name">
                      {{element.name}}
                    </div>
                  </draggable>
                </div>
            
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import draggable from 'vuedraggable';

export default {
  name: 'HomeView',
  components: {
    draggable,
    // HelloWorld
  },
  data() {
    return {
      newTask: "",
      arrBacklog: [
        {name: "Code Sign Up Page"},
        {name: "Test Dashboard"},
        {name: "Style Registration"},
        {name: "Help with Designs"},
      ],
      arrInProgress:[],
      arrTested: [],
      arrDone: [],
      newShortcut1: "",
      shortcuts1: []
    }
  },
  methods: {
    add() {
      if(this.newTask) {
        this.arrBacklog.push({name: this.newTask});
        this.newTask="";
      }
      if(this.newShortcut1) {
        this.shortcuts1.push({title: this.newShortcut1, description: (this.newShortcut1 + ' desc')});
        this.newShortcut1="";
      }
    }
  },
  mounted() {
   // fetch('http://localhost:3000/shortcuts')
  // fetch('/server/routes/api/shortcuts')
  // fetch('../routes/api/shortcuts')
  // fetch('../../routes/api/shortcuts')
  // fetch('http://localhost:27017/server/routes/api/shortcuts')
  // fetch('mongodb+srv://localhost:27017/server/routes/api/shortcuts')
  // fetch('routes/api/shortcuts')

  // below fails bc no https 
  // https://stackoverflow.com/questions/66534759/cors-error-on-request-to-localhost-dev-server-from-remote-site
  //   fetch('http://localhost:27017/server/routes/api/shortcuts', {
  //     targetAddressSpace: "private",
  // })

  // below works on prod!
  // fetch('https://44.228.191.227:27017/routes/api/shortcuts')
  fetch('https://localhost:27017/routes/api/shortcuts', {
    targetAddressSpace: "private",
  })
    .then(res => res.json())
    .then(data => this.shortcuts1 = data)
    .catch(err => console.log('Hey NOW!', err.message))
  }
}
</script>